// extracted by mini-css-extract-plugin
export var alignCenter = "Table__alignCenter__c1cyI";
export var alignLeft = "Table__alignLeft__hvjiZ";
export var alignRight = "Table__alignRight__kOc5S";
export var column = "Table__column__HAHFP";
export var flex = "Table__flex__uIA4V";
export var flexColumn = "Table__flexColumn__y1w63";
export var gap1 = "Table__gap1__yYAI_";
export var gap2 = "Table__gap2__ihigJ";
export var gap3 = "Table__gap3__kE9uc";
export var gap4 = "Table__gap4__Rk2CY";
export var gap5 = "Table__gap5__B3NOY";
export var hideOnMobile = "Table__hideOnMobile__g58qh";
export var primary = "Table__primary__fzfM0";
export var row = "Table__row__KedsK";
// extracted by mini-css-extract-plugin
export var column = "Control__column__IQwyf";
export var control = "Control__control__ijHLR";
export var flex = "Control__flex__Msizq";
export var flexColumn = "Control__flexColumn__CsO9V";
export var gap1 = "Control__gap1__x5R38";
export var gap2 = "Control__gap2__B6mpW";
export var gap3 = "Control__gap3__Kvmr6";
export var gap4 = "Control__gap4__IvUBv";
export var gap5 = "Control__gap5__nBE8n";
export var gray = "Control__gray__u6lFv";
export var inline = "Control__inline__gYeEf";
export var inverted = "Control__inverted__HNetu";
export var link = "Control__link__SGEcs";
export var row = "Control__row__u3OmW";
export var teal = "Control__teal__lY4OQ";
export var white = "Control__white__2X4eD";
// extracted by mini-css-extract-plugin
export var barContainer = "LocationsDashboardNavbar__barContainer__tTEru";
export var barWrapper = "LocationsDashboardNavbar__barWrapper__zVtnW";
export var column = "LocationsDashboardNavbar__column__s0puD";
export var flex = "LocationsDashboardNavbar__flex__p0YV0";
export var flexColumn = "LocationsDashboardNavbar__flexColumn__oWiQW";
export var gap1 = "LocationsDashboardNavbar__gap1__SfZmm";
export var gap2 = "LocationsDashboardNavbar__gap2__wFjo_";
export var gap3 = "LocationsDashboardNavbar__gap3__tQS9l";
export var gap4 = "LocationsDashboardNavbar__gap4__quJqh";
export var gap5 = "LocationsDashboardNavbar__gap5__cHlpW";
export var linkContainer = "LocationsDashboardNavbar__linkContainer__zMm6m";
export var linkItem = "LocationsDashboardNavbar__linkItem__GDSzZ";
export var row = "LocationsDashboardNavbar__row__xqH8W";
export var underlinedLink = "LocationsDashboardNavbar__underlinedLink__q1EHX";